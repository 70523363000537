import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useSocialMedias = () => {

  // base data
  const dialog = ref(false);
  const socialMediasEdited = ref(null);
  const socialMedias = ref([]);
  const loading = ref(false);

  // computed properties
  const titleDialog = computed(() =>
    socialMediasEdited.value ? "Editar Redes Sociales" : "Agregar Redes Sociales"
  );
  const subtitleDialog = computed(() =>
    socialMediasEdited.value ?
      "Rellene los campos correctamente para modificar las redes sociales" :
      "Rellene los campos correctamente para validar las redes sociales"
  );

  const closeDialog = () => {
    dialog.value = false;
    socialMediasEdited.value = null;
  }

  // methods
  const getSocialMediasList = async () => {
    const { data, status } = await store.dispatch("fetchSocialMedias")
    if (status != 200) return;
    socialMedias.value = data;
  }

  const createSocialMedias = async (socialMedias) => {
    loading.value = true;
    const { data, status } = await store.dispatch("createSocialMedias", socialMedias)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getSocialMediasList();
    closeDialog();
  }

  const updateSocialMedias = async (socialMedias) => {
    loading.value = true;
    const { data, status } = await store.dispatch("updateSocialMedias", socialMedias)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getSocialMediasList();
    closeDialog();
  }

  const removeSocialMedias = async (socialMedias) => {
    const val = await store.dispatch("generateConfirmDialog", {});
    if (!val) return;
    const { data, status } = await store.dispatch("removeSocialMedias", socialMedias)
    if (status != 200 && status != 201 && status != 204) return;
    await getSocialMediasList();
  }

  getSocialMediasList();

  return {
    dialog,
    socialMediasEdited,
    socialMedias,
    loading,
    // computed
    titleDialog,
    subtitleDialog,
    // methods
    openDialog() {
      dialog.value = true;
    },
    openEdit(socialMedias) {
      dialog.value = true;
      socialMediasEdited.value = { ...socialMedias };
    },
    openRemove: removeSocialMedias,
    closeDialog,
    createSocialMedias,
    updateSocialMedias
  };
}

export default useSocialMedias;
