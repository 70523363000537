<template>
  <v-card>
    <BasicInformationHeader></BasicInformationHeader>
    <section class="form-full-width">
      <FormBasicInformation
        :loading="loading"
        :getBasicInformation="getBasicInformation"
        @beforeUpdate="updateBasicInformation"
      ></FormBasicInformation>
    </section>
  </v-card>
</template>

<script>
import FormBasicInformation from '@/components/forms/FormBasicInformation.vue'
import BasicInformationHeader from './BasicInformationHeader.vue'
import useBasicInformation from '@/composables/useBasicInformation'

export default {
  components: {
    FormBasicInformation,
    BasicInformationHeader,
  },
  setup() {
    // Composition API
    const {
      BasicInformation,
      loading,
      // methods
      getBasicInformation,
      updateBasicInformation,
    } = useBasicInformation()

    return {
      BasicInformation,
      loading,
      // methods
      getBasicInformation,
      updateBasicInformation,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-full-width {
  padding: 10px;
}
</style>
