<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formBasicInformation" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="2">
            <label for="link_table_parts">Link de mesa de partes</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="link_table_parts"
              v-model="basicInformation.link_table_parts"
              outlined
              dense
              placeholder="www.google.com"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="link_portal_transparency">Link de portal de transparencia</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="link_portal_transparency"
              v-model="basicInformation.link_portal_transparency"
              outlined
              dense
              placeholder="https://www.transparencia.gob.pe"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="file_color">Logo a color</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="file_color"
              v-model="file_color"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileChange"
            ></v-file-input>
          </v-col>

          <v-col cols="12" md="2">
            <label for="file_white">Logo en blanco</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="file_white"
              v-model="file_white"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileNewChange"
            ></v-file-input>
          </v-col>

          <v-col cols="12" md="6">
            <div class="section">
              <v-row>
                <v-col cols="12" md="6" class="center-img">
                  <img class="img-about" draggable="false" :src="url || loadImage(basicInformation.logo_color)" />
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <div class="section">
              <v-row>
                <v-col cols="12" md="6" class="center-img">
                  <img class="img-about" draggable="false" :src="urlNew || loadImage(basicInformation.logo_white)" />
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col cols="12" md="2">
            <label for="direction">Direccion</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="direction"
              v-model="basicInformation.address"
              outlined
              dense
              placeholder="Jr. Ucayali 266"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="telephone">Telefono</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="telephone"
              v-model="basicInformation.telephone"
              outlined
              dense
              placeholder="(01) 000-0000"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="email">Correo</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-text-field
              id="email"
              v-model="basicInformation.email"
              outlined
              dense
              placeholder="correo@email.com"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <label for="file_municipality">Logo de la municipalidad</label>
          </v-col>

          <v-col cols="12" md="4" class="pb-0">
            <v-file-input
              id="file_municipality"
              v-model="file_municipality"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige una imagen"
              @change="onFileOther"
            ></v-file-input>
          </v-col>

          <v-col cols="12">
            <div class="section">
              <v-row>
                <v-col cols="12" md="6" class="center-img">
                  <img
                    class="img-about"
                    draggable="false"
                    :src="urlOther || loadImage(basicInformation.icon_municipality)"
                  />
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>

          <v-col cols="12">
            <h3>Redes Sociales</h3>
          </v-col>

          <v-col cols="12">
            <DataTableSocialMedias></DataTableSocialMedias>
          </v-col>


        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import DataTableSocialMedias from '@/components/data-table/DataTableSocialMedias.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    FormTemplate,
    DataTableSocialMedias
  },
  props: {
    getBasicInformation: Function,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      urlNew: '',
      urlOther: '',
      file_color: null,
      file_white: null,
      file_municipality: null,
      basicInformation: {},
    }
  },

  async created() {
    this.basicInformation = await this.getBasicInformation()
  },

  methods: {
    validateForm() {
      return this.$refs.formBasicInformation.validate()
    },
    reset() {
      this.$refs.formBasicInformation.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      let DTO = { ...this.basicInformation }

      if (this.file_color) {
        DTO = {
          ...DTO,
          file_color: this.file_color,
        }
      }
      if (this.file_white) {
        DTO = {
          ...DTO,
          file_white: this.file_white,
        }
      }
      if (this.file_municipality) {
        DTO = {
          ...DTO,
          file_municipality: this.file_municipality,
        }
      }
      this.$emit('beforeUpdate', DTO)
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
    onFileNewChange(e) {
      if (!e) return
      this.urlNew = URL.createObjectURL(e)
    },
    onFileOther(e) {
      if (!e) return
      this.urlOther = URL.createObjectURL(e)
    },
  },
}
</script>

<style lang="scss" scoped>
.img-about {
  max-width: 100%;
  max-height: 450px;
}

.title-about {
  font-size: 25px;
  font-weight: 800;
  text-transform: uppercase;
}

.content-about {
  display: flex;
  align-items: center;
}

.text-about {
  white-space: pre-wrap;
  font-size: 14px;
}

.section {
  margin: 0 auto;
  max-width: 900px;
  .center-img {
    margin: 0 auto !important;
  }
}

.btn {
  margin-top: 15px;
  background: #e83964;
  padding: 0.5em 1.5em;
  color: white;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.btn span {
  display: inline-block;
  position: relative;
  margin-left: 0;
}
</style>
