import { ref } from "@vue/composition-api";
import store from "@/store/index"

const useBasicInformation = () => {

  // base data
  const basicInformation = ref(null);
  const loading = ref(false);

  // methods
  const getBasicInformation = async () => {
    loading.value = true;
    const { data, status } = await store.dispatch("fetchBasicInformation");
    loading.value = false;
    if (status != 200) return;
    basicInformation.value = data;
    return data;
  }

  const updateBasicInformation = async (basicInformation) => {
    loading.value = true;
    const { data, status } = await store.dispatch("updateBasicInformation", basicInformation)
    loading.value = false;
    if (status != 200 && status != 201) return;
    return data;
  }


  return {
    basicInformation,
    loading,
    // methods
    getBasicInformation,
    updateBasicInformation,
  };
}

export default useBasicInformation;
