<template>
  <div>
    <div class="row-title-btn">
      <v-card-title>Información basica</v-card-title>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.row-title-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  position: relative;
  min-height: 60px;
}
.input-control {
  width: 50px;
  max-height: 60px;
  height: 60px;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  top: 0;
  transition: all ease-in-out 0.25s;
  transform-origin: initial;
  .app-bar-search {
    width: 100%;
  }
}
.input-control.active {
  position: absolute;
  width: 100%;
  right: 0;
  z-index: 5;
  padding: 4px 16px;
  animation: searcher ease-in-out 0.25s;
}
@keyframes searcher {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.controls-actions {
  display: flex;
  align-items: center;
}
</style>
