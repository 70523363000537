<template>
  <v-card class="elevation-0">
    <div>
      <div class="row-title-btn">
        <div class="controls-actions d-flex justify-end">
          <v-btn @click="openDialog" icon class="ml-2">
            <v-icon size="24">{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="socialMedias"
      :loading="loading"
      item-key="name"
      class="table-rounded datatable-height elevation-3"
      fixed-header
      disable-sort
      hide-default-footer
    >
      <!-- icon -->
      <template #[`item.icon`]="{ item }">
        <div class="img-officer center-sub-items">
          <img draggable="false" :src="loadImage(item.icon)" :alt="item.description" />
        </div>
      </template>
      <!-- name -->
      <template #[`item.description`]="{ item }">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.description }}</span>
          <!-- <small>{{ item.post }}</small> -->
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="d-flex">
          <CrudButtons @edit="openEdit(item)" @remove="openRemove(item)"></CrudButtons>
        </div>
      </template>
    </v-data-table>
    <WDialog width="600" :dialog="dialog" :closeDialog="closeDialog" :title="titleDialog" :subtitle="subtitleDialog">
      <template v-slot:form-dialog>
        <FormSocialMedias
          v-if="dialog"
          :loading="loading"
          :socialMediasEdited="socialMediasEdited"
          @beforeUpdate="updateSocialMedias"
          @beforeCreate="createSocialMedias"
        ></FormSocialMedias>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import CrudButtons from '@/components/buttons/CrudButtons.vue'
import FormSocialMedias from '@/components/forms/FormSocialMedias.vue'
import useSocialMedias from '@/composables/useSocialMedias'
import WDialog from '@/components/dialogs/WDialog.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    CrudButtons,
    WDialog,
    FormSocialMedias,
  },
  setup() {
    const {
      dialog,
      socialMediasEdited,
      socialMedias,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createSocialMedias,
      updateSocialMedias,
    } = useSocialMedias()

    const loadImage = src => {
      return loadAPIImage(src)
    }

    return {
      icons: {
        mdiPlus,
      },
      dialog,
      socialMediasEdited,
      socialMedias,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      loadImage,
      // crud
      createSocialMedias,
      updateSocialMedias,
      headers: [
        { text: 'ICONO', value: 'icon' },
        { text: 'NOMBRE', value: 'description' },
        { text: 'ENLACE', value: 'url_social_medias' },
        { text: '', value: 'actions', align: 'right' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.icon-service {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #00aca0;
  padding: 10px;
  margin: 10px;
}

.icon-service img {
  max-width: 50px;
  max-height: 50px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-officer {
  padding: 10px;
}

.img-officer img {
  max-width: 80px;
  max-height: 80px;
}
</style>
